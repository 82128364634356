body, html {
  margin: 0px;
  overflow: hidden;
  background-color: #000;
}

header > img {
  margin-top: -60px;
  margin-bottom:-60px;
}

@media only screen and (max-width: 700px) {
  header > img {
    margin-top: -20px;
    margin-bottom:-20px;
    width:100%;
  }
}

main {
  position: absolute;
  background-color: #000;

  width: 100vw;
  height: 100vh;
  padding: 5px;
  overflow:hidden;
}

#mobileInput {
  position: absolute;
  top: -10px;
  left: -10px;
  width: 0px;
  height: 0px;
}